<template>
  <div class="elv-transactions-table-toolbar-content">
    <div class="elv-transactions-table-toolbar-content-left">
      <p>{{ formatNumber(props.selectedTotal, 2) }} {{ transformI18n($t('common.selected')) }}</p>
      <span @click="onClearSelected">{{ transformI18n($t('button.clear')) }}</span>
    </div>
    <div class="elv-transactions-table-toolbar-content-right">
      <!-- <div class="elv-transactions-table-toolbar-content-right__export" @click="onExportTransactions">
        <SvgIcon name="reports-export" width="14" height="14" fill="#838D95" />
        {{ transformI18n($t('button.export')) }}({{ formatNumber(props.selectedTotal, 2) }})
      </div> -->
      <div
        class="elv-transactions-table-toolbar-content-right__create"
        :class="{ 'is-disabled': isDisabled }"
        @click="onBatchCreateJournal"
      >
        <SvgIcon name="source-add" width="14" height="14" /> {{ transformI18n($t('title.createJournal')) }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ElMessage } from 'element-plus'
import { map, isEmpty } from 'lodash-es'
import { formatNumber } from '@/lib/utils'
import { $t, transformI18n } from '@/i18n/index'
import SvgIcon from '@/components/Base/SvgIcon.vue'
import { useEntityStore } from '@/stores/modules/entity'

const props = defineProps({
  selectedTotal: {
    type: Number,
    default: 0
  },
  multipleSelection: {
    type: Array,
    default: () => []
  }
})

const entityStore = useEntityStore()

const emit = defineEmits(['exportTransactions', 'batchCreateJournal', 'clearSelected'])

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const isDisabled = computed(() => {
  const isEveryEqual =
    !isEmpty(props.multipleSelection) &&
    !map(props.multipleSelection, 'direction').every((val, i, arr) => val === arr[0])
  const isEveryEmptyChartOfAccount = map(props.multipleSelection, 'journal.journalActivityId').some(
    (val) => val !== undefined
  )
  return isEveryEqual || isEveryEmptyChartOfAccount
})

const onClearSelected = () => {
  emit('clearSelected')
}

// eslint-disable-next-line no-unused-vars
const onExportTransactions = () => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.transaction?.export
  ) {
    ElMessage.warning(transformI18n($t('message.noPermission')))
    return
  }
  emit('exportTransactions')
}

const onBatchCreateJournal = () => {
  if (['MEMBER', ''].includes(currentEntityPermission.value?.role) && !currentEntityPermission.value?.journal?.create) {
    ElMessage.warning(transformI18n($t('message.noPermission')))
    return
  }
  if (isDisabled.value) return
  emit('batchCreateJournal')
}
</script>

<style lang="scss" scoped>
.elv-transactions-table-toolbar-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .elv-transactions-table-toolbar-content-left {
    display: flex;
    align-items: center;
    font-family: 'Plus Jakarta Sans';

    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      color: #fff;
    }

    span {
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #abc0f5;
      margin-left: 16px;
      cursor: pointer;
    }
  }

  .elv-transactions-table-toolbar-content-right {
    display: flex;
    align-items: center;
    font-family: 'Plus Jakarta Sans';

    .elv-transactions-table-toolbar-content-right__export {
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-right: 16px;
      cursor: pointer;

      svg {
        margin-right: 6px;
      }
    }

    .elv-transactions-table-toolbar-content-right__create {
      display: flex;
      height: 30px;
      padding: 0px 12px;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 22px;
      cursor: pointer;
      background: #1753eb;
      color: #fff;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      svg {
        fill: #fff;
        margin-right: 4px;
      }

      &.is-disabled {
        background: #636b75;
        color: #838d95;
        cursor: not-allowed;

        svg {
          fill: #838d95;
        }
      }
    }
  }
}
</style>
